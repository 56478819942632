<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item>
        <el-input v-model="dataForm.key" placeholder="参数名" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button v-if="isAuth('wechat:orgs:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>
        <el-button v-if="isAuth('wechat:orgs:delete')" type="danger" @click="deleteHandle()"
                   :disabled="dataListSelections.length <= 0">批量删除
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="dataList"
        border
        row-key="id"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        style="width: 100%;">
      <!--      <el-table-column-->
      <!--        type="selection"-->
      <!--        header-align="center"-->
      <!--        align="center"-->
      <!--        width="50">-->
      <!--      </el-table-column>-->
      <!--      <el-table-column-->
      <!--        prop="id"-->
      <!--        header-align="center"-->
      <!--        align="center"-->
      <!--        label="序号">-->
      <!--      </el-table-column>-->
      <el-table-column
          prop="name"
          header-align="center"
          label="机构名称">
      </el-table-column>
      <el-table-column
          prop="code"
          header-align="center"
          align="center"
          label="机构代码">
      </el-table-column>
      <el-table-column
          prop="updateTime"
          header-align="center"
          align="center"
          label="更新时间">
        <template slot-scope="scope">
          <span>{{ $utils.dateFormat('yyyy-MM-dd hh:mm', scope.row.updateTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          width="150"
          label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible"
                   :orgList="dataList"
                   ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from './orgs-add-or-update'
import {treeDataTranslate} from '@/utils'
import PageMixin from "@/mixins/Page";

export default {
  data() {
    return {
      dataForm: {
        key: ''
      },
    }
  },
  mixins: [PageMixin],
  components: {
    AddOrUpdate
  },
  activated() {
    this.getDataList()
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.doPromiseGetList('/wechat/orgs/list').then(({data}) => {
        if (data && data.code === 0) {
          this.dataList = treeDataTranslate(data.data, 'id', 'pid')
        } else {
          this.dataList = []
        }
        this.dataListLoading = false
      })
    },
    // 删除
    deleteHandle(id) {
      this.doDelete('/wechat/orgs/delete', id)
    }
  }
}
</script>
